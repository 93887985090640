import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';


class NotFound404 extends Component {
    render() {
        return (
            <div style={{margin:'50px 0'}}>
                <h1 className="notFound_title">404</h1>
                <p className="notFound_subTitle">Oups, il semblerait qu'il y ait un problème...</p>
                <p className="notFound_container">
                    Cette page n'existe pas ou l'adresse entrée n'est pas bonne.
                    Vérifier qu'il n'y ait pas d'erreurs dans l'url ou retourner à la page d'accueil.

                </p>
                <button className="button_cta color">
                    <NavLink exact className="menu_item" to="/">Retour à l'accueil</NavLink>
                </button>
            </div>
        )
    }
}

export default NotFound404