import React, {Component} from 'react';
import logo from "../../miysis_logo.jpg";
import archive from "../../documents/Miysis_images_HD.zip";
import presentation from "../../documents/Presentation Miysis 2019.pdf";
import videoHD from "../../documents/miysis_3d_notre_dame_animation_WL.mp4";
import { withTranslation } from 'react-i18next';


import Button from "./../form/Button";
import { NavLink } from 'react-router-dom';


const menuZ = {
    zIndex: '1111'
}

const burgerMenu = {
    color: 'black!important',
    textDecoration: 'none'
}

const position = {
    relative: {
        position: 'relative'
    },
    fixed: {
        position: 'fixed',
        top: 0
    }
}


class NavBar extends Component {
    state = {
        isOpen: false,
        isFixed: false
    }

    myScript = () => {

        if (window.pageYOffset >= 50) {
            this.setState({isFixed: true})
        } else {
            this.setState({isFixed: false})
        }
    }

    componentDidMount = () => {
        window.addEventListener("scroll", this.myScript);
    }

    close = () => {
        this.setState({isOpen: false})
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen})
    }

    render() {
        const { isOpen, isFixed } = this.state
        const { t } = this.props
        return (
            <div style={isFixed ? position.fixed : position.relative } className="navbar_container">
                <div className="logo_container">
                    <NavLink className="menu_item" to="/">
                        <img src={logo} alt="Miysis logo"/>
                    </NavLink>
                </div>

                <div className="menu_container">
                    <div className="desktop_container">
                        <Button>
                            <a href="https://www.miysis.be/">{t('Go to miysis')}</a>
                        </Button>
                        <Button>
                            <a href="#gallery_anchor">{t('Go to gallery')}</a>
                        </Button>
                        <Button title={t('Download')} path={archive} >
                            <i className="fas fa-download" />
                        </Button>
                        <Button title={t('Download PDF')} path={presentation} >
                            <i className="fas fa-file-pdf" />
                        </Button>
                        <Button title={t('Download MP4')} path={videoHD} >
                            <i className="fas fa-film" />
                        </Button>
                        <NavLink className="btn-lang" to="/en">EN</NavLink>
                        <NavLink className="btn-lang" to="/fr">FR</NavLink>
                    </div>

                    <button className="btn_burger" onClick={this.toggle}>
                        <i className="fas fa-bars" />
                    </button>
                    { isOpen &&
                        <div style={menuZ} className="burger_container">
                            <ul>
                                <li>
                                    <a style={burgerMenu} href="https://www.miysis.be/">{t('Go to miysis')}</a>
                                </li>
                                <li>
                                    <a style={burgerMenu} href="#gallery_anchor">{t('Go to gallery')}</a>
                                </li>
                                <li>
                                    <a style={burgerMenu} href={archive} download="Miysis_images_HD.zip">{t('Download')}</a>
                                </li>
                                <li>
                                    <a style={burgerMenu} href={presentation} download="Presentation Miysis 2019.pdf">{t('Download PDF')}</a>
                                </li>
                                <li>
                                    <a style={burgerMenu} href={videoHD} download="Video Miysis 2019.mp4">{t('Download MP4')}</a>
                                </li>
                                <li>
                                    <NavLink style={burgerMenu} className="btn-lang" to="/en">EN</NavLink>
                                </li>
                                <li>
                                    <NavLink style={burgerMenu} className="btn-lang" to="/fr">FR</NavLink>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withTranslation()(NavBar);