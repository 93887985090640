import React  from 'react';


const Button = ({ title, path, border, children }) => {
    return (
        <button className={border ? "btn_cta border" : "btn_cta color" } >
            <a href={path} download>{title}</a>
            <span>{children}</span>
        </button>
    );
}

export default Button;